<template>
  <div class="warehouse-viewer" ref="warehouse_viewer">
    <div v-if="selectedRow">
      {{ selectedRow.userData }}
    </div>
    <div class="d-flex">
      <button
        class="btn btn-no-r"
        :class="!editorMode ? 'btn-primary' : 'btn-secondary'"
        v-on:click="editorMode = false"
      >
        Stock
      </button>
      <button
        class="btn btn-no-l"
        :class="editorMode ? 'btn-primary' : 'btn-secondary'"
        v-on:click="editorMode = true"
      >
        Editor
      </button>
    </div>
    <div id="ui">
      <h3>Warehouse Editor</h3>
      <label>Warehouse Width:</label>
      <input type="number" v-model="warehouseWidth" />
      <label>Warehouse Height:</label>
      <input type="number" v-model="warehouseHeight" />
      <h4>Add Row</h4>
      <label>X Position:</label>
      <input type="number" id="rowX" value="0" />
      <label>Y Position:</label>
      <input type="number" id="rowY" value="0" />
      <label>Row Length:</label>
      <input type="number" id="rowLength" value="5" />
      <button v-on:click="addRow()">Add Row</button>
      <h4>File Options</h4>
      <button v-on:click="saveWarehouse()">Save JSON</button>
      <input type="file" id="loadFile" v-on:change="loadWarehouse()" />
      <button v-on:click="refreshFullness()">Refresh stock</button>
      <button v-on:click="setMovement()">set Movement</button>
    </div>
  </div>
</template>

<script>
import * as THREE from "three";
import { MeshLine, MeshLineMaterial } from "three.meshline";

let scene = null;
let camera = null;
let renderer = null;

let zoomDistance = 10;
let wall1,
  wall2,
  wall3,
  wall4,
  floor,
  gridHelper = null;
// Rows storage
const rows = [];

export default {
  data() {
    return {
      warehouseWidth: 10,
      warehouseHeight: 10,
      rows: [],
      selectedRow: null,
      editorMode: false,
    };
  },
  beforeUnmount() {
    renderer.dispose();
    renderer.domElement.parentNode.removeChild(renderer.domElement);
  },
  mounted() {
    // Set up scene, camera, and renderer
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(
      75,
      (this.$refs.warehouse_viewer.offsetWidth - 40) /
        (window.innerHeight - 180),
      0.1,
      1000
    );
    renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.setSize(
      this.$refs.warehouse_viewer.offsetWidth - 40,
      window.innerHeight - 180
    );
    document.body.appendChild(renderer.domElement);

    this.setWarehouseSize();

    // Mouse controls
    let isRightMouseDown = false;
    let isLeftMouseDown = false;
    let prevMousePosition = { x: 0, y: 0 };

    // Camera settings
    camera.position.set(0, 0, 10);
    camera.lookAt(0, 0, 0);
    let zoomSpeed = 1;
    let cameraTarget = new THREE.Vector3(0, 0, 0);

    // Light settings
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(0, 5, 20);
    directionalLight.target.position.set(0, 0, 0);
    directionalLight.castShadow = true;
    scene.add(directionalLight);

    const ambientLight = new THREE.AmbientLight(0x404040, 0.5); // soft white light
    scene.add(ambientLight);

    // Mouse controls for moving and resizing rows
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    let selectedRow = null;
    let isResizing = false;

    const _this = this;

    document.addEventListener("click", () => {
      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObjects(
        rows.flatMap((row) => row.threeObject),
        true
      );

      if (intersects.length > 0) {
        const clickedObject = intersects[0].object;
        console.log("Clicked object:", clickedObject);
        _this.selectedRow = clickedObject;
      }
    });

    // Mouse events
    document.addEventListener("mousedown", (event) => {
      if (event.button === 2) isRightMouseDown = true; // Right mouse button
      if (event.button === 0) isLeftMouseDown = true; // Left mouse button
      prevMousePosition.x = event.clientX;
      prevMousePosition.y = event.clientY;

      // Normalize mouse coordinates ([-1, 1] range)
      const canvasBounds = renderer.domElement.getBoundingClientRect();

      // Convert mouse position to normalized device coordinates (NDC)
      mouse.x =
        ((event.clientX - canvasBounds.left) / canvasBounds.width) * 2 - 1;
      mouse.y =
        -((event.clientY - canvasBounds.top) / canvasBounds.height) * 2 + 1;

      // Update raycaster
      raycaster.setFromCamera(mouse, camera);

      // Check for intersection with rows (or compartments)
      const intersects = raycaster.intersectObjects(
        rows.flatMap((row) => row.threeObject),
        true
      );

      if (intersects.length > 0) {
        selectedRow = intersects[0].object.parent;
        isResizing = event.shiftKey;
      }
    });

    document.addEventListener("mouseup", () => {
      isRightMouseDown = false;
      isLeftMouseDown = false;
    });

    document.addEventListener("mousemove", (event) => {
      if (this.editorMode && isLeftMouseDown && selectedRow) {
        if (isResizing) {
          const deltaX = event.clientX - prevMousePosition.x;
          if (selectedRow.scale.x > 0.01 || deltaX > 0) {
            selectedRow.scale.x += deltaX * 0.01;
          }
        } else {
          // Logic for moving the selected row
          const deltaX =
            Math.round(event.clientX / 10) * 10 -
            Math.round(prevMousePosition.x / 10) * 10;
          const deltaY =
            Math.round(event.clientY / 10) * 10 -
            Math.round(prevMousePosition.y / 10) * 10;

          const radians = scene.rotation.z;

          const xNew = deltaX * Math.cos(radians) - deltaY * Math.sin(radians);
          const yNew = deltaX * Math.sin(radians) + deltaY * Math.cos(radians);

          selectedRow.position.x += xNew * 0.015;
          selectedRow.position.y -= yNew * 0.015;
        }
        prevMousePosition.x = event.clientX;
        prevMousePosition.y = event.clientY;

        return;
      }

      const deltaX = event.clientX - prevMousePosition.x;
      const deltaY = event.clientY - prevMousePosition.y;
      prevMousePosition.x = event.clientX;
      prevMousePosition.y = event.clientY;

      if (isRightMouseDown) {
        // Rotate camera (orbit around target)
        const angleY = deltaY * 0.005;
        const angleX = deltaX * 0.005;

        scene.rotation.z += Math.PI * angleX;
        const offset = new THREE.Vector3().subVectors(
          camera.position,
          cameraTarget
        );

        // Rotate the offset vector around the X axis (vertical rotation)
        offset.applyAxisAngle(new THREE.Vector3(1, 0, 0), -angleY);

        // Set the camera position
        camera.position.copy(cameraTarget).add(offset);
        camera.lookAt(cameraTarget);
      }

      if (isLeftMouseDown) {
        const panSpeed = 0.01;

        const cameraRight = new THREE.Vector3();
        cameraRight
          .crossVectors(
            camera.up,
            camera.getWorldDirection(new THREE.Vector3())
          )
          .normalize();
        const cameraUp = new THREE.Vector3(0, 0, 1);

        // Calculate pan offset
        const panOffset = new THREE.Vector3();
        panOffset.addScaledVector(
          cameraRight,
          (deltaX * panSpeed * zoomDistance) / 10
        ); // Pan horizontally
        panOffset.addScaledVector(
          cameraUp.cross(cameraRight).normalize(),
          (-deltaY * panSpeed * zoomDistance) / 10
        ); // Pan vertically

        camera.position.add(panOffset);
        cameraTarget.add(panOffset);
      }
    });

    document.addEventListener("mouseup", () => {
      selectedRow = null;
      isResizing = false;
    });

    const minZoomDistance = 2; // Minimum distance to the target
    const maxZoomDistance = 300; // Maximum distance to the target

    // Mouse wheel for zoom
    document.addEventListener("wheel", (event) => {
      const zoomAmount = event.deltaY * 0.01 * zoomSpeed;
      const direction = new THREE.Vector3()
        .subVectors(camera.position, cameraTarget)
        .normalize();

      // Calculate the new position
      const newPosition = camera.position
        .clone()
        .addScaledVector(direction, zoomAmount);

      // Calculate the distance to the target
      const distanceToTarget = newPosition.distanceTo(cameraTarget);

      // Clamp the distance to within min and max zoom limits
      if (
        distanceToTarget >= minZoomDistance &&
        distanceToTarget <= maxZoomDistance
      ) {
        zoomDistance = distanceToTarget;
        camera.position.copy(newPosition);
      }
    });

    // Disable default right-click menu
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    // Handle window resize
    window.addEventListener("resize", () => {
      camera.aspect =
        (_this.$refs.warehouse_viewer.offsetWidth - 40) /
        (window.innerHeight - 180);
      camera.updateProjectionMatrix();
      renderer.setSize(
        _this.$refs.warehouse_viewer.offsetWidth - 40,
        window.innerHeight - 180
      );
    });

    // Animation loop
    function animate() {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    }
    animate();
  },
  watch: {
    warehouseHeight() {
      this.setWarehouseSize();
    },
    warehouseWidth() {
      this.setWarehouseSize();
    },
    editorMode() {
      for (let i = 0; i < rows.length; i++) {
        const compartments = rows[i].threeObject.children;

        for (let i = 0; i < compartments.length; i++) {
          this.setCompartmentColor(compartments[i]);
        }
      }
    },
  },
  methods: {
    loadWarehouse() {
      const file = document.getElementById("loadFile").files[0];
      const reader = new FileReader();
      const _this = this;
      reader.onload = function (event) {
        const data = JSON.parse(event.target.result);
        _this.warehouseWidth = data.warehouse.width;
        _this.warehouseHeight = data.warehouse.height;
        _this.setWarehouseSize();

        // Remove existing rows
        rows.forEach((row) => scene.remove(row));
        rows.length = 0;

        // Add rows from the file
        data.rows.forEach((row) => {
          _this.addNewRow(row.x, row.y, row.length, row.numCompartments);
        });
      };
      reader.readAsText(file);
    },
    saveWarehouse() {
      const data = {
        warehouse: { width: this.warehouseWidth, height: this.warehouseHeight },
        rows: rows.map((e) => ({
          x: e.threeObject.position.x,
          y: e.threeObject.position.y,
          length: e.length,
          numCompartments: e.numCompartments,
        })),
      };
      const blob = new Blob([JSON.stringify(data)], {
        type: "application/json",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "warehouse.json";
      link.click();
    },
    setMovement() {
      const robotPath = [
        { x: 0, y: 0 },
        { x: 0, y: 5 },
        { x: 9, y: 5 },
        { x: 9, y: 3 },
      ];

      const points = robotPath.map(
        (coord) => new THREE.Vector3(coord.x, coord.y, 0.02)
      );

      const line = new MeshLine();
      line.setPoints(points);

      const material = new MeshLineMaterial({
        color: 0xff0000,
        lineWidth: 0.2,
        dashArray: 1,
        dashOffset: 1,
        dashRatio: 0,
      });

      const lineMesh = new THREE.Mesh(line, material);
      scene.add(lineMesh);
    },
    setWarehouseSize() {
      // Remove the previous grid helper if it exists
      if (gridHelper) scene.remove(gridHelper);

      // Create the grid helper with divisions matching the floor dimensions
      gridHelper = new THREE.GridHelper(
        Math.max(this.warehouseWidth, this.warehouseHeight),
        Math.max(this.warehouseWidth, this.warehouseHeight),
        0xbbbbbb,
        0xaaaaaa
      ); // Darker grid lines
      gridHelper.position.set(0, 0, 0.01); // Position the grid slightly above the floor
      gridHelper.rotation.x = -Math.PI / 2; // Rotate the floor to lie flat on the XY plane
      // Add the grid helper to the scene
      scene.add(gridHelper);

      // Remove the previous floor if it exists
      if (floor) scene.remove(floor);

      // Create the floor geometry based on width and height
      const floorGeometry = new THREE.PlaneGeometry(
        this.warehouseWidth,
        this.warehouseHeight
      );
      const textureLoader = new THREE.TextureLoader();
      const floorTexture = textureLoader.load("/textures/floor.jpg");
      floorTexture.colorSpace = THREE.SRGBColorSpace;
      floorTexture.wrapS = THREE.RepeatWrapping;
      floorTexture.wrapT = THREE.RepeatWrapping;
      floorTexture.repeat.set(2, 2);
      const floorMaterial = new THREE.MeshBasicMaterial({
        map: floorTexture,
        color: 0xd3d3d3,
        side: THREE.DoubleSide,
      });
      floor = new THREE.Mesh(floorGeometry, floorMaterial);

      floor.position.set(0, 0, 0); // Position the floor in the center
      scene.add(floor);

      let geometry = new THREE.BoxGeometry(this.warehouseWidth, 1, 0.2);
      const texture = textureLoader.load("/textures/zinc.jpg");
      texture.colorSpace = THREE.SRGBColorSpace;
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(10, 2);

      const material = new THREE.MeshBasicMaterial({ map: texture });

      if (wall1) scene.remove(wall1);
      wall1 = new THREE.Mesh(geometry, material);
      wall1.rotation.x = -Math.PI / 2;
      wall1.position.set(0, this.warehouseHeight / 2, 0.5);
      scene.add(wall1);

      if (wall2) scene.remove(wall2);
      wall2 = new THREE.Mesh(geometry, material);
      wall2.rotation.x = -Math.PI / 2;
      wall2.position.set(0, this.warehouseHeight / -2, 0.5);
      scene.add(wall2);

      if (wall3) scene.remove(wall3);
      geometry = new THREE.BoxGeometry(this.warehouseHeight, 1, 0.2);
      wall3 = new THREE.Mesh(geometry, material);
      wall3.rotation.x = -Math.PI / 2;
      wall3.rotation.y = -Math.PI / 2;
      wall3.position.set(this.warehouseWidth / 2, 0, 0.5);
      scene.add(wall3);

      if (wall4) scene.remove(wall4);
      wall4 = new THREE.Mesh(geometry, material);
      wall4.rotation.x = -Math.PI / 2;
      wall4.rotation.y = -Math.PI / 2;
      wall4.position.set(this.warehouseWidth / -2, 0, 0.5);
      scene.add(wall4);
    },
    addNewRow(x, y, length, numCompartments) {
      // Group to hold the compartments
      const rowGroup = new THREE.Group();

      for (let i = 0; i < numCompartments; i++) {
        const textureLoader = new THREE.TextureLoader();
        const topTexture = textureLoader.load("/textures/shelf.jpg");
        const sideTextureFront = textureLoader.load("/textures/boxes.png");
        const sideTextureBack = textureLoader.load("/textures/boxes.png");
        sideTextureBack.center.set(0.5, 0.5);
        sideTextureBack.rotation = Math.PI;
        const sideTextureRight = textureLoader.load("/textures/boxes.png");
        sideTextureRight.center.set(0.5, 0.5);
        sideTextureRight.rotation = Math.PI / 2;
        const sideTextureLeft = textureLoader.load("/textures/boxes.png");
        sideTextureRight.center.set(0.5, 0.5);
        sideTextureLeft.rotation = Math.PI / -2;

        const materials = [
          new THREE.MeshBasicMaterial({
            map: sideTextureRight,
            color: 0xe5dafb,
          }), // top
          new THREE.MeshBasicMaterial({
            map: sideTextureLeft,
            color: 0xe5dafb,
          }), //side
          new THREE.MeshBasicMaterial({
            map: sideTextureBack,
            color: 0xe5dafb,
          }), //back
          new THREE.MeshBasicMaterial({
            map: sideTextureFront,
            color: 0xe5dafb,
          }), //front
          new THREE.MeshBasicMaterial({ map: topTexture, color: 0xe5dafb }), //side
          new THREE.MeshBasicMaterial({ map: topTexture, color: 0xe5dafb }), //bottom
        ];

        // Create each compartment
        const compartmentWidth = length / numCompartments;
        const geometry = new THREE.BoxGeometry(compartmentWidth, 0.4, 0.5);
        const compartment = new THREE.Mesh(geometry, materials);

        // Position each compartment
        compartment.position.set(
          x + i * compartmentWidth - length / 2,
          y,
          0.25
        );
        compartment.userData.fullness = 0; // Initial fullness level (0 for empty)

        rowGroup.add(compartment);
      }

      scene.add(rowGroup);

      // Save the row to the list (with compartments)
      rows.push({ length, numCompartments, threeObject: rowGroup });
    },
    refreshFullness() {
      for (let i = 0; i < rows.length; i++) {
        let rndValues = [];
        for (let i2 = 0; i2 < rows[i].numCompartments; i2++) {
          rndValues.push(Math.random());
        }
        this.updateRowFullness(i, rndValues);
      }
    },
    addRow() {
      const x = parseInt(document.getElementById("rowX").value);
      const y = parseInt(document.getElementById("rowY").value);
      const length = parseInt(document.getElementById("rowLength").value);
      const numCompartments = length * 2;

      this.addNewRow(x, y, length, numCompartments);
    },
    setCompartmentFullness(compartment, fullness) {
      fullness = Math.max(0, Math.min(1, fullness));
      compartment.userData.fullness = fullness;

      this.setCompartmentColor(compartment);
    },

    setCompartmentColor(compartment) {
      const fullness = compartment.userData.fullness;
      // Interpolate RGB values
      const startColor = { r: 229, g: 218, b: 251 }; // White
      const endColor = { r: 113, g: 58, b: 232 }; // Dark

      const r = Math.round(
        startColor.r +
          (endColor.r - startColor.r) * (this.editorMode ? 0 : fullness)
      );
      const g = Math.round(
        startColor.g +
          (endColor.g - startColor.g) * (this.editorMode ? 0 : fullness)
      );
      const b = Math.round(
        startColor.b +
          (endColor.b - startColor.b) * (this.editorMode ? 0 : fullness)
      );

      // Convert to hexadecimal color
      const colorHex = (r << 16) | (g << 8) | b; // Combine RGB to a single hexadecimal value
      for (const material of compartment.material) {
        material.color.setHex(colorHex);
      }
    },

    updateRowFullness(rowIndex, fullnessLevels) {
      if (rowIndex < 0 || rowIndex >= rows.length) return;

      const row = rows[rowIndex];
      const compartments = row.threeObject.children;

      for (let i = 0; i < compartments.length; i++) {
        const fullness = fullnessLevels[i] || 0; // Default to 0 if no value provided
        this.setCompartmentFullness(compartments[i], fullness);
      }
    },
  },
};
</script>

<style scoped>
.warehouse-viewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
#ui {
  position: absolute;
  top: 80px;
  right: 80px;
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 5px;
}
input,
button {
  margin: 5px 0;
  display: block;
}
</style>
